<template>
   <div class="lightening-deals-wrap section-gap pb-0 pt-0">
      <v-container grid-list-xl>
         <div class="sec-wrap primary py-4 px-6">
            <div class="layout align-center c-layout justify-space-between pa-4">
               <div class="sec-title">
                  <h2 class="white--text mb-0 text-xxl">{{secTitle}}</h2>
               </div>
               <router-link to="/menu"  class="white--text text-sm text-uppercase font-weight-medium">Ver todos</router-link>
            </div>
         </div>
         <div class="shop-card-gap px-6 py-12">
            <v-layout row wrap>
               <v-flex xs12 sm6 md6 lg3 xl3 v-for="item in items" :key="item.id">
                  <div class="emb-card box-shadow-md pa-6 white">
                     <a href="javascript:void(0)">
                        <img v-lazyload :data-src="item.image" alt="Product" >
                     </a>
                     <div class="emb-card-content pt-4">
                        <h5>
                           <routerLink to="/menu" class="caption">{{item.name}}</routerLink>
                        </h5>
                        <!-- <p>
                           <routerLink to="/menu" class="text-muted">{{item.category}}</routerLink>
                        </p> -->
                        <!-- <p class="accent--text mb-0">{{item.offer}}</p> -->
                     </div>
                  </div>
               </v-flex>
            </v-layout>
         </div>
      </v-container>
   </div>
</template>

<script>
   import lazyload from 'Directives/lazyload'
   export default {
      props: ['data', 'secTitle'],
      data() {
			return {
            resource: 'latest-items',
				items: []
			}
		},
      directives: {
			lazyload
      },
      async created() {
			await this.getItems()
		},
		methods: {
			async getItems() {
            await this.$http.get(`/${this.resource}/items`).then(response => { 
                  this.items = response.data
               })
			}
		}
   }
   
</script>
