export default [
    {
        image: "/static/images/free-delivery.png",
        subTitle: "ENVÍO GRATIS",
        title: "Cada día, cada orden",
        desc: "*Para todos los pedidos"
    },
    {
        image: "/static/images/customer-support.png",
        subTitle: "CALL CENTER",
        title: "Atendemos pedidos mediante llamadas",
        desc: "*Llamar mínimo 20 min. antes"
    },
    {
        image: "/static/images/money-back.png",
        subTitle: "SEGURO",
        title: "Transacciones cifradas",
        desc: "*Simple pero seguro"
    }
]