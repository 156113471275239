export default [
   {
      icon: "mdi-cart",
      subTitle: "Entrega Rápida",
      desc: "Atendemos los pedidos lo antes posible, para que nuestros clientes estén satisfechos."
   },
   {
      icon: "mdi-thumb-up",
      subTitle: "Buena Preparación",
      desc: "Nuestra sazón es única e inconfundible, nuestros clientes pueden dar fé de ello."
   },
   {
      icon: "mdi-fire",
      subTitle: "Precios Accesibles",
      desc: "Nosotros entendemos la situción en la que nos encontramos, por ello no te conbramos el envío."
   },
]