<template>
   <div class="collection-gallery-wrap section-gap overlay-section">
      <v-container grid-list-xl>
         <v-layout row wrap>
            <v-flex xs12 sm12 md4 lg3 xl3>
               <div class="main-bg overlay-section-item overlay-section-overlay relative" style="background-image: url('static/images/promotions1.png');background-size:cover;">
                  <div class="end-left">
                     <div class="overlay-section-content">
                        <h4 class="mb-0 font-bold">Promociones</h4>
                        <h4 class="text-xxl font-normal mb-4">Pídelo ahora mismo!</h4>
                        <v-btn class="ma-0" color="accent">Ver Promociones</v-btn>
                     </div>
                  </div>
               </div>
            </v-flex>
            <v-flex xs12 sm12 md8 lg9 xl9>
               <v-layout row wrap>
                  <v-flex xs12 sm12 md6 lg6 xl6>
                     <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('static/images/broiled1.png')">
                        <div class="end-left">
                           <div class="overlay-section-content">
                           <h4 class="mb-0 font-bold">Pollos a la Brasa</h4>
                           <!-- <h4 class=" text-xxl font-normal mb-4">Save Upto 50%</h4> -->
                           <v-btn class="ma-0" color="accent" @click="getItemsByCategory(1)">Ver Platos</v-btn>
                           </div>
                        </div>
                     </div>
                  </v-flex>
                  <v-flex xs12 sm12 md6 lg6 xl6>
                     <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('static/images/salads1.png')">
                        <div class="end-left">
                           <div class="overlay-section-content">
                           <h4 class="mb-0 font-bold">Ensaladas</h4>
                           <h4 class=" text-xxl font-normal mb-4">¡Verduras frescas!</h4>
                           <v-btn class="ma-0" color="accent" @click="getItemsByCategory(3)">Ver Platos</v-btn>
                           </div>
                        </div>
                     </div>
                  </v-flex>
                  <v-flex xs12 sm12 md6 lg6 xl6>
                     <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('static/images/wood-fired.png');background-size:cover;">
                        <div class="end-left">
                           <div class="overlay-section-content">
                              <h4 class="mb-0 font-bold">Pollos a la Leña</h4>
                              <!-- <h4 class=" text-xxl font-normal mb-4">Save Upto 60%</h4> -->
                              <v-btn class="ma-0" color="accent" @click="getItemsByCategory(2)">Ver Platos</v-btn>
                           </div>
                        </div>
                     </div>
                  </v-flex>
                  <v-flex xs12 sm12 md6 lg6 xl6>
                     <div class="main-bg-inner overlay-section-item overlay-section-overlay relative" style="background-image: url('static/images/beverages.png');background-size:cover;">
                        <div class="end-left">
                           <div class="overlay-section-content">
                              <h4 class="mb-0 font-bold">Bebidas</h4>
                              <h4 class=" text-xxl font-normal mb-4">¡Pídelo helada o sin helar!</h4>
                              <v-btn class="ma-0" color="accent" @click="getItemsByCategory(4)">Ver Bebidas</v-btn>
                           </div>
                        </div>
                     </div>
                  </v-flex>
               </v-layout>
            </v-flex>
         </v-layout>
      </v-container>
   </div>
</template>

<script>
   import lazyload from 'Directives/lazyload'
   export default {
      data() {
			return {
				
			};
      },
      directives: {
			lazyload
      },
      methods: {
         async getItemsByCategory(categoryId) {
            await this.$eventHub.$emit('getItemsByCategory', categoryId)
            await this.$router.push({name: 'menu'})
         }
      }
   }
</script>
