<template>
	<div class="home-banner-v3 mt-14 pt-10" v-if="loaded">
		<v-container grid-list-xl>
			<slick ref="carousel" :options="slickOptions">
				<div class="slider-wrap text-center" v-for="(sliderItem, key) in data" :key="key" style="max-height: 500px">
					<div class="slider-item relative">
						<img :src="sliderItem.image" alt="slide-item" height="100%" width="100%" />
						<div class="slider-content-v3">
							<div class="slider-content-v3-inner ma-auto">
								<p class="text-inverse">{{ sliderItem.subHeading }}</p>
								<h3 class="white--text">{{ sliderItem.heading }}</h3>
								<v-btn color="accent" @click.prevent="addProductToCart(sliderItem)"
									>ORDENAR</v-btn
								>
							</div>
						</div>
					</div>
				</div>
			</slick>
		</v-container>

		<emb-location-dialog
			:showLocationDialog.sync="showLocationDialog"
			:isRouteMenu="true"
			:lat="lat"
			:lng="lng"
			:draggable="true"
			:autoAdd="false"
			:item="null"
		>
		</emb-location-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Slick from 'vue-slick';

export default {
	// props: ['data'],
	components: { Slick },
	data() {
		return {
			data: [],
			loaded: false,
			lat: null,
			lng: null,
			showLocationDialog: false,
			slickOptions: {
				slidesToShow: 1,
				infinite: true,
				swipe: true,
				autoplay: true,
				dots: true,
				arrows: false,
				pauseOnHover: true,
				adaptiveHeight: true,
				// centerMode: true,
				// variableWidth: true,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							arrows: false,
						},
					},
				],
			},
		};
	},
	computed: {
		...mapGetters(['cart', 'wishlist', 'location']),
	},
	async created() {
		await this.getPromotionalItems();
	},
	methods: {
		next() {
			this.$refs.slick.next();
		},
		prev() {
			this.$refs.slick.prev();
		},
		reInit() {
			// Helpful if you have to deal with v-for to update dynamic lists
			this.$nextTick(() => {
				this.$refs.slick.reSlick();
			});
		},
		...mapActions({
			addNotification: 'addNotification',
		}),
		getPromotionalItems() {
			this.$http.get(`/banner/data`).then((response) => {
				this.data = response.data;
				this.loaded = true;
			});
		},
		addProductToCart(item) {
			if (this.location) {
				if (!this.ifItemExistInCart(item)) {
					setTimeout(() => {
						this.$store.dispatch('addProductToCart', item);
					}, 100);

					this.addNotification({
						show: true,
						type: 'success',
						message: 'El Producto ha sido agregado al carrito satisfactoriamente',
					});
				} else {
					this.addNotification({
						show: true,
						type: 'error',
						message: 'El Producto ya ha sido agregado al carrito',
					});
				}
			} else {
				this.showLocationDialog = true;
			}
		},
		/**
		 * method for checking if item exists in cart
		 */
		ifItemExistInCart(result) {
			let exists = false;
			if (this.cart) {
				for (let item of this.cart) {
					if (item.item_id == result.id) {
						exists = true;
					}
				}
			}
			return exists;
		},
	},
};
</script>
