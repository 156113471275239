<template>
	<div>
		<!-- <img src="/static/images/delivery-2.svg" alt="Delivery" width="260"> -->
		<h1 color="white">¡Realiza tus pedidos online!</h1>
		<p>
			¡Porque te cuidamos, llegamos a ti! Llevamos tu plato favorito con todos los protocolos
			de protección de forma rápida y segura.
		</p>
		<!-- <p>
            1. Para recibir el pedido, es necesario que te coloques tu mascarilla, nuestro repartidor también lo hará.
            2. El Motorizado contará con implementos de limpieza y desinfección antes, durante y después a la entrega.
            3. Mantendremos 2 mts. de distancia durante todo el proceso de entrega para cuidarte y cuidarnos.
            4. Contamos con un proceso diferenciado para pago en efectivo. Sin embargo, para mayor comodidad y evitar 100% contacto, te recomendamos hacer el pago en línea.
            5. Todas las entregas se realizarán en la puerta o recepción del edificio o condominio.
        </p> -->

		<v-btn
			color="accent"
			rounded
			dark
			class="my-1"
			large
			@click.prevent="getCurrentPosition()"
		>
			Usar mi ubicación actual
		</v-btn>
		<v-btn color="accent" rounded class="mx-2 my-1" dark large @click="showDialog = true">
			Números de contacto
		</v-btn>

		<emb-location-dialog
			:showLocationDialog.sync="showLocationDialog"
			:lat="lat"
			:lng="lng"
			:draggable="true"
			:autoAdd="false"
			:item="null"
		>
		</emb-location-dialog>

		<emb-call :showDialog.sync="showDialog"></emb-call>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showLocationDialog: false,
			showDialog: false,
			lat: null,
			lng: null,
		};
	},
	methods: {
		getCurrentPosition() {
			if (navigator.geolocation) {
				//check if geolocation is available
				navigator.geolocation.getCurrentPosition(
					this.successPosition,
					this.failurePosition,
					{ timeout: 60000 }
				);
			}
		},
		async successPosition(position) {
			this.lat = position.coords.latitude;
			this.lng = position.coords.longitude;
			this.showLocationDialog = true;
		},
		failurePosition(err) {
			switch (err.code) {
				case 1:
					this.$message.error(
						'Para mejorar la experiencia de usuario, habilita el acceso a su ubicación.'
					);
					break;
				default:
					console.warn('ERROR(' + err.code + '): ' + err.message);
					break;
			}
		},
	},
};
</script>
