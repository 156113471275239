<template>
    <div class="main">
        <div class="banner-wrap">
            <emb-home-main-banner-v3></emb-home-main-banner-v3>
        </div>


        <!-- <audio controls autoplay>
           <source src="/static/audio/alert.mp3" type="audio/mpeg">
        </audio> -->

        <v-container grid-list-xl my-8>
            <v-layout row wrap>
                <v-flex xs12 sm12 md12 lg12 xl12 text-center>
                    <emb-home-location></emb-home-location>
                </v-flex>
            </v-layout>
        </v-container>

        <feature-v2 
            :data="featureV2Data"
        >
        </feature-v2>

        <collection-gallery></collection-gallery>

        <!-- <shop-by-category 
            :data="shopbyCategoryData" 
            secTitle="Shop By Category"
        >
        </shop-by-category> -->

        <lightening-deals 
            :data="LighteningDealsData" 
            secTitle="Nuevos platos"
        >
        </lightening-deals>

        <emb-features
            :data="featuresData"
        >
        </emb-features>

        <!-- <banner></banner> -->

        <!-- <div class="top-brands-wrap-v2">
            <emb-shop-card class="pt-0" secTitle="Top Brands" :data="topBrandsData"></emb-shop-card>
        </div> -->

        <subscribe-v2
            heading="Suscríbase a nuestro boletín"
            description="Manténgase actualizado con nuestras últimas novedades y productos."
        >
        </subscribe-v2>

        <!-- <download-app></download-app> -->
        
    </div>
    
</template>

<script>
    // layout components
    // import HomeMainBanner from "Components/Layouts/Banner/HomeMainBanner";
    import HomeMainBannerV3 from "Components/Layouts/Banner/HomeMainBannerV3";
    // widgets
    import Features from "Components/Widgets/Feature";
    import FeatureV2 from "Components/Widgets/FeatureV2";
    import CollectionGallery from "Components/Widgets/CollectionGallery";
    // import ShopByCategory from "Components/Widgets/ShopByCategory";
    // import FeatureProductV2 from "Components/Widgets/FeatureProductV2";
    import LighteningDeals from "Components/Widgets/LighteningDeals";
    // import Banner from "Components/Widgets/BannerV1";
    // import ShopCard from "Components/Widgets/ShopCard";
    import SubscribeV2 from "Components/Widgets/SubscribeV2";
    // import DownloadApp from "Components/Widgets/DownloadApp";
    // data
    // import homeBannerData from "Assets/data/homeBannerData";
    // import homeBannerV3Data from "Assets/data/homeBannerV3Data";
    import LighteningDealsData from "Assets/data/lighteningDealsData";
    import featureV2Data from "Assets/data/featureV2Data";
    // import shopbyCategoryData from "Assets/data/shopbyCategoryData";
    import featuresData from "Assets/data/featuresData";
    import featureProductV2Data from "Assets/data/featureProductV2Data";
    // import topBrandsData from "Assets/data/topBrandsData";
    import HomeLocation from 'Views/User/Location/HomeLocation';

    export default {
        components: {
            // embHomeMainBanner: HomeMainBanner,
            embHomeMainBannerV3: HomeMainBannerV3,
            embFeatures: Features,
            featureV2: FeatureV2,
            lighteningDeals:LighteningDeals,
            collectionGallery: CollectionGallery,
            // ShopByCategory: ShopByCategory,
            // embFeatureProductV2: FeatureProductV2,
            // Banner: Banner,
            // embShopCard: ShopCard,
            subscribeV2: SubscribeV2,
            // downloadApp: DownloadApp,
            embHomeLocation: HomeLocation,
        },
        data() {
            return {
                homeBannerData: [],
                // homeBannerV3Data,
                featuresData,
                featureV2Data,
                LighteningDealsData,
                // shopbyCategoryData,
                featureProductV2Data,
                // topBrandsData
            }
        }
    }
</script>
